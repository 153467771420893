// @ts-nocheck
import Footer from '@/components/Footer'
import LoadingPlaySVG from '@/src/components/ListenLiveButton/LoadingPlaySVG'
import PlayButtonSVG from '@/src/components/ListenLiveButton/PlayButtonSvg'
import StopButtonSVG from '@/src/components/ListenLiveButton/StopButtonSVG'
import { getDictionary, isHot97 } from '@/src/dictionary'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import React, { memo, useCallback, useEffect, useLayoutEffect, useState } from 'react'

const Ad = dynamic(() => import('@/src/components/Ad'), { ssr: false })
const ListenLiveButton = dynamic(() => import('@/src/components/ListenLiveButton'), { ssr: false })
const HeaderMenu = dynamic(() => import('@/src/components/HeaderMenu/components'), { ssr: false })

interface LayoutProps {
    children: React.ReactNode,
    home?: boolean,
    utilityMenuItems: any[],
    socialMenuItems: any[],
    mainMenuItems: any[],
    preLoadedFont: NextFontWithVariable
}

const Layout = ({ children, mainMenuItems, socialMenuItems, utilityMenuItems, preLoadedFont }: LayoutProps) => {
    const [isLoading, setIsLoading] = useState(false)
    const [isPlaying, setIsPlaying] = useState(false)
    const [track, setTrack] = useState({})
    const router = useRouter()

    const handlePlay = useCallback(() => {
        if (!isPlaying) {
            setIsLoading(true)
            {/* @ts-ignore */ }
            widgetAdPlayer.trigger('play');
        } else {
            {/* @ts-ignore */ }
            setIsLoading(false)
            widgetAdPlayer.trigger('stop')
        }
    }, [isPlaying])

    useEffect(() => {
        const playButton = document.querySelector('#player-button');
        if (playButton) {
            playButton.style.display = 'block'
        }

        // improvements on SEO
        Array.from(document.getElementsByClassName("triton-pixel")).forEach(
            function (element) {
                element.alt = ''
            }
        );
        const policyText = document.querySelector('#pc-policy-text > a')
        if (policyText) {
            policyText.innerHTML = 'More information about privacy policy'
        }

        const callbackIsPlaying = () => {
            setIsPlaying(true)
            setIsLoading(false)
        };

        const callbackIsStop = () => {
            setIsPlaying(false)
        };

        const setTrackInfo = (trackInfo: any) => {
            setIsLoading(false)
            setTrack(trackInfo)
        };
        eventEmitter.on('streamStart', callbackIsPlaying);
        eventEmitter.on('streamStopped', callbackIsStop);
        eventEmitter.on('onTrack', setTrackInfo);

        return () => {
            eventEmitter.off('streamStart', callbackIsPlaying);
            eventEmitter.off('streamStopped', callbackIsStop);
            eventEmitter.off('onTrack', setTrackInfo);
        };
    }, [track, isPlaying]);

    useLayoutEffect(() => {
        // start auto-playing song when in the listen-live page to prevent song to stop and to start playing to the user
        // this is a workaround because td-player doesnt works well with next-js Link routing

        if (router.pathname === '/listen-live') {
            const playButton = document.querySelector('#player-button');
            playButton?.dispatchEvent(new Event('click'))
        }
    }, [router.pathname])

    return (
        <div className="w-screen">
            {
                <div className={`sticky top-0 w-full bg-black z-50 hidden lg:block ${router.asPath.includes('listen-live') ? 'hidden' : ''}`}>
                    {/* @ts-ignore */}
                    <td-player
                        id='td-player-header'
                        type='bar'
                        station={getDictionary('listenStation')}
                        highlightcolor={`${isHot97() ? '#FDB827' : '#BA1B1B'}`}
                        primarycolor={`${isHot97() ? '#FDB827' : '#BA1B1B'}`}
                        secondarycolor="#000"
                        autostart={'false'}
                        onappready='appReady'
                        onstreamstart='onStreamStart'
                        onstreamstopped='onStreamStopped'
                        buybtnvisible='false'
                        ontrack='onTrack'
                    />
                    <div className='hidden'>
                        <td-songhistory 
                            id='td-songhistory'
                            station={getDictionary('listenStation')}
                            songsdisplayed='10'
                            title='Recently played'
                            highlightcolor={getDictionary('listenPrimaryColor')}
                            primarycolor='#ffffff'
                            onappready='appReady' />
                    </div>

                    <div className='absolute items-center align-middle left-[47%] rounded-full p-2 top-[5px]' style={{ 'display': 'none' }} onClick={handlePlay} id="player-button">
                        {
                            isLoading ? (
                                <LoadingPlaySVG fill={`${isHot97() ? '#FDB827' : '#BA1B1B'}`} />
                            ) :
                                isPlaying ? (
                                    <StopButtonSVG fill={`${isHot97() ? '#FDB827' : '#BA1B1B'}`} />
                                ) : (
                                    <PlayButtonSVG fill={`${isHot97() ? '#FDB827' : '#BA1B1B'}`} />
                                )
                        }
                    </div>
                </div>
            }
            <HeaderMenu isPost={router.asPath.includes(getDictionary('postsPath'))} mainMenuItems={mainMenuItems} socialMenuItems={socialMenuItems} utilityMenuItems={utilityMenuItems} preLoadedFont={preLoadedFont} />
            {children}
            <Footer mainMenuItems={mainMenuItems} socialMenuItems={socialMenuItems} footerMenuItems={utilityMenuItems} />
            <Ad id='anchor-ad' pos='anchor' closeButton={true} className={'flex w-fit relative mt-4'} shouldAddNew category={undefined} postId={undefined} />
            <ListenLiveButton handlePlay={handlePlay} track={track} isLoading={isLoading} isPlaying={isPlaying} />
        </div>
    )
}

export default memo(Layout)
