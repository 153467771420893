import { client } from '@/contentful/client'

export const getAllMenus = async () => {
    return await client.getEntries({
        content_type: 'menu',
    })
}

export const getMenuByType = async (type) => {
    return await client.getEntries({
        content_type: 'menu',
        'fields.menuType[in]': type,
    })
}
