export function showSearch() {
    const classStyles = 'show-search visible__search'
    classStyles.split(' ').map((style) => {
        document.body.classList.add(style)
    })
}

export function hideSearch() {
    setTimeout(() => {
        document.body.classList.remove('visible__search')
    }, 500)
    document.body.classList.remove('show-search')
}

export function findTalentBySlug(talents, slug) {
    let result = null
    talents.forEach(element => {
        if (element.fields.slug == slug) {
            result = element
            return
        }
    })
    return result
}