import Link from "next/link"
import { renderSocialLink } from "./icons"

interface SocialMediaProps {
    socialItems: any[]
    footer?: boolean
    buttonStyles?: string
}

export function SocialMedia({ socialItems, footer, buttonStyles }: SocialMediaProps) {
    return (
        <nav aria-label='Social media' className='flex items-center justify-evenly'>
            {footer && <span className="text-hot-yellow text-sm leading-normal pr-2 font-[Judge]">Follow us on social media</span>}
            {
                socialItems.map(item => {
                    return (
                        renderSocialLink(item, footer, buttonStyles)
                    )
                })
            }
        </nav>
    )
}

export function SocialButtons({ socialItems }: any) {
    return (
        <div className='flex flex-wrap'>
            {
                socialItems.map((item: any) => {
                    return (
                        <Link prefetch={false} className='wp-block-button__link'
                            href={item.path}
                            key={item.path}
                            target='_blank'
                            rel='noreferrer noopener'>
                            {item.name}
                        </Link>
                    )
                })
            }
        </div>
    )
}
