import { showSearch } from '@/components/utils/search-controls'
import { faFacebook, faInstagram, faXTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons'
import { faBars, faChevronLeft, faSearch, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Link from 'next/link'

// The following import prevents a Font Awesome icon server-side rendering bug,
// where the icons flash from a very large icon down to a properly sized one:
import '@fortawesome/fontawesome-svg-core/styles.css';
// Prevent fontawesome from adding its CSS since we did it manually above:
import { config } from '@fortawesome/fontawesome-svg-core';
config.autoAddCss = false; /* eslint-disable import/first */

export function renderSocialLink(socialItemMenu, footer, buttonStyles) {
    return (
        <Link prefetch={false} key={socialItemMenu.name} href={socialItemMenu.path}
            className={`text-base leading-3 ${buttonStyles}`}
            target='_blank'>
            <span title={socialItemMenu.name} className={`${buttonStyles}`}>
                <FontAwesomeIcon icon={getSocialIcon(socialItemMenu.name)} height={25} aria-hidden='true' color={`${footer ? 'text-white' : 'text-black'}`} />
            </span>
            <span className='sr-only'>{socialItemMenu.name}</span>
        </Link>
    )
}

function getSocialIcon(name) {
    switch (name) {
        case 'facebook':
            return faFacebook
        case 'twitter':
        case 'twitter-x':
        case 'x':
            return faXTwitter
        case 'instagram':
            return faInstagram
        case 'youtube':
            return faYoutube
    }
}

export function SearchButton() {
    const className = `md:p-0 decoration-none border-none`
    return (
        <button className={className} onClick={() => showSearch()}>
            <FontAwesomeIcon icon={faSearch} fontSize={36}  aria-hidden='true' />
        </button>
    )
}

export function OpenMenuIcon() {
    return (
        <FontAwesomeIcon icon={faBars} aria-hidden='true' fontSize={36} aria-label='open mobile menu' />
    )
}

export function CloseMenuIcon() {
    return (
        <FontAwesomeIcon icon={faTimes} aria-hidden='true' fontSize={36} aria-label='close mobile menu has-white-color' />
    )
}

export function CloseSearchIcon() {
    return (
        <FontAwesomeIcon icon={faTimes} aria-hidden='true' fontSize={36} />
    )
}

export function BackButtonIcon() {
    return (
        <FontAwesomeIcon icon={faChevronLeft} aria-hidden='true' fontSize={36} aria-label='back button mobile' />
    )
}
