import '@/styles/hs-eu-cookie.css'
import '@/styles/rich-media-ads-global.css'

import Comscore from '@/src/components/Comscore'
import Layout from '@/src/pages/components/layout'
import localFont from 'next/font/local'
import Script from 'next/script'
import { GoogleAnalytics } from 'nextjs-google-analytics'

import '@/styles/font-awesome.css'
import '@/styles/globals.css'
import App, { AppContext, AppInitialProps } from 'next/app'
import { getAllMenus } from '../contentful/getMenu'
import Head from 'next/head'
import { isHot97 } from '@/src/dictionary'
import React from 'react'
import(`${`@/styles/${process.env.NEXT_PUBLIC_TARGET}/dist-styles-theme.css`}`)
import(`${`@/styles/${process.env.NEXT_PUBLIC_TARGET}/jw-player-jwppp-video-hero.css`}`)


const fontHot97 = localFont({
    src: [{
        path: '../../public/fonts/F37Judge-Medium.woff'
    }],
    variable: "--font-Judge"
})

type AppOwnProps = {
    mainMenuItems: any,
    utilityMenuItems: any,
    socialMenuItems: any
}

function MyApp({ Component, pageProps, mainMenuItems, utilityMenuItems, socialMenuItems }: AppContext & AppInitialProps & AppOwnProps) {
    return (
        <>
            <Head>
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            </Head>
            <Script id="viewport" strategy='lazyOnload'>
            {`
            const resize = () => {
                const viewport = document.createElement("meta");
                viewport.setAttribute("name","viewport");
                if (screen.width <= 300) {
                    viewport.setAttribute("content", "width=device-width, initial-scale=0.5, user-scalable=no");
                } 
                else if (screen.width <= 400) {
                    viewport.setAttribute("content", "width=device-width, initial-scale=0.7, user-scalable=no");
                } 
                else if (screen.width <= 500) {
                    viewport.setAttribute("content", "width=device-width, initial-scale=0.8, user-scalable=no");
                } 
                else {
                    viewport.setAttribute("content", "width=device-width, initial-scale=1");
                }
                document.head.appendChild(viewport);
            }
            resize();
            window.addEventListener("orientationchange", resize);
            window.addEventListener("resize", resize);
            `} 
            </Script>
            <Layout socialMenuItems={socialMenuItems} mainMenuItems={mainMenuItems} utilityMenuItems={utilityMenuItems} preLoadedFont={fontHot97}>
                <GoogleAnalytics trackPageViews strategy='lazyOnload' gaMeasurementId={process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID} />
                <Comscore />
                <Component {...pageProps} />               
                <Script
                    type='text/javascript'
                    defer={true}
                    strategy='beforeInteractive'
                    src={isHot97() ? '/js/hot97/ione-min-ads-colab-js-src-dfp-loader.js' : '/js/wbls/ione-min-ads-colab-js-src-dfp-loader.js'}
                />
            </Layout>
        </>
    )
}

interface MenuItem {
    name: string;
    path: string;
}

function mapItems(items: any[]): MenuItem[] {
    return items?.map((item: any) => item?.fields?.item?.map((field: any) => ({
        name: field.fields.name,
        path: field.fields.path
    }))).flat() || [];
}

MyApp.getInitialProps = async function (context: AppContext): Promise<AppInitialProps & AppOwnProps> {
    const ctx = await App.getInitialProps(context)

    const allMenus = await getAllMenus()
    const mainMenu = allMenus.items.filter((menu) => menu.fields.menuType === 'main')
    const footerUtilityMenu = allMenus.items.filter((menu) => menu.fields.menuType === 'footerUtility')
    const socialsMenu = allMenus.items.filter((menu) => menu.fields.menuType === 'socials')

    const mainMenuItems: MenuItem[] = mapItems(mainMenu);
    const utilityMenuItems: MenuItem[] = mapItems(footerUtilityMenu);
    const socialMenuItems: MenuItem[] = mapItems(socialsMenu);

    return {
        ...ctx,
        mainMenuItems,
        utilityMenuItems,
        socialMenuItems
    }
}

export default MyApp
