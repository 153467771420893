const dictionary = {
    hot97: {
        indexTitle: 'HOT 97 - Home of Hip Hop Since 1992',
        indexDescription: 'Hot 97 is THE place for artist news, new music, videos, interviews, and everything else you need to know in the world of hip-hop.',
        talentTitle: 'Hot Talent',
        carouselTalentViewText: 'View All Hot97 Talent',
        url: 'https://www.hot97.com',
        jwPlayerLibraryId: 'mniPLjSs',
        indexTalentMarquee: 'Where Hip-Hop Lives',
        subfooterHeadline: 'Tap dat app.',
        subfooterTagline: 'The NEW Hot97 app is your source for music and breaking news in the world of Hip-Hop. Don\'t miss a beat, download the free app today.',
        googleAppLink: 'https://play.google.com/store/apps/details?id=com.jacapps.whhl&amp;gl=US',
        appleAppLink: 'https://apps.apple.com/us/app/hot97-official/id990780132',
        footerCompTagline: '#1 for Hip-Hop',
        websiteName: 'Hot 97',
        salesEmail: 'SALES@HOT97.COM',
        contactAddress: 'HOT 97 395 Hudson St. 7th Fl. ,New York, NY 10014',
        contactGeneralInfoPhone: '(212) 229-9797',
        contactGeneralFax: '(212) 524-9855',
        contactContestLine: '(800) 223-9797',
        contactGeneralEmail: 'info@hot97.com',
        contactMediaInfoEmail: 'press@hot97.com',
        contactLastLine: 'Advertise with Hot 97',
        contactLastLinePhone: '(212) 229-9797',
        prefooterSubhead: 'Don&apos;t miss a beat',
        prefooterHeadline: 'Tap dat app.',
        prefooterLinkText: 'Download the free app',
        prefooterLink: 'https://apps.apple.com/us/app/hot97-official/id990780132',
        title: 'hot97',
        listenPrimaryColor: '#FDB927',
        listenStation: 'WQHTFM',
        pressEntry: 'WQHT FM HOT 97.1 is part of the MediaCo New York radio cluster, alongside sister-station WBLS 107.5 FM.',
        pressOficialBoilerPlate: `WQHT HOT 97 FM is the world's first all Hip-Hop station and has held the dominant position in New York for over twenty years. HOT 97 is best known for its celebrity on-air talent, such as Ebro Darden (Asst. Programming Director, radio host for Beats1), Funk Flex (television host, multiple gold records), and Nessa (television personality and host for MTV), as well as being the world's premier source for Hip Hop music culture. HOT97 online and the Where Hip-Hop Lives app are the destinations for original web series, celebrity content, concerts, and music videos. For more information, please explore this site, and follow us on social:`,
        subscribeText: '',
        subscribeHubspotId: '90362a24-2ecb-43fd-a684-83dffe418999',
        contestRulesCompanyName: 'd/b/a WQHT HOT 97 (“HOT 97” or “MediaCo”)',
        talentPath: '/talent/',
        postsPath: '/hot-news/',
        postsName: 'Latest news',
        topStories: 'Top Stories',
        moreFrom: 'MORE FROM HOT97',
        defaultAuthor: 'Hot97 Staff',
        adsTag: 'https://pubads.g.doubleclick.net/gampad/ads?iu=/4052,22551419753/hot97/videopreroll&description_url=https%3A%2F%2Fwww.hot97.com%2F&tfcd=0&npa=0&sz=602x400&gdfp_req=1&output=vast&unviewed_position_start=1&env=vp&impl=s&correlator='
    },
    wbls: {
        indexTitle: 'Home | WBLS',
        indexDescription: 'WBLS FM is a radio station that serves the New York City area and plays a wide range of R&B, soul, and hip-hop music.',
        talentTitle: 'Talent',
        carouselTalentViewText: 'View DJs',
        url: 'https://www.wbls.com',
        jwPlayerLibraryId: 'LvAT1twI',
        indexTalentMarquee: '#1 FOR R&B', 
        subfooterHeadline: 'DOWNLOAD THE WBLS APP',
        subfooterTagline: 'The NEW WBLS App is your source for music and breaking news in the world of R&B. Download the free app from the App Store or Google Play.',
        googleAppLink: 'https://play.google.com/store/apps/details?id=com.liquidcompass.WBLSFM&hl=en_US&gl=US',
        appleAppLink: 'https://apps.apple.com/us/app/wbls-107-5fm/id589532846',
        footerCompTagline: '#1 for R&B',
        websiteName: 'WBLS',
        salesEmail: 'SALES@WBLS.COM',
        contactAddress: 'WBLS 395 Hudson St. 7th Fl. New York, NY 10014',
        contactGeneralInfoPhone: '(212) 447-1000',
        contactGeneralFax: '(212) 524-9855',
        contactContestLine: '(212) 545-1075',
        contactGeneralEmail: 'info@wbls.com',
        contactMediaInfoEmail: 'press@wbls.com',
        contactLastLine: 'Steve Harvey Call in #',
        contactLastLinePhone: '(877) 29-STEVE',
        prefooterSubhead: 'LISTEN ANYWHERE',
        prefooterHeadline: 'THE WBLS APP',
        prefooterLinkText: 'CLICK & DOWNLOAD',
        prefooterLink: 'https://onelink.to/wblsapp',
        title: 'wbls',
        listenPrimaryColor: '#BA1B1B',
        listenStation: 'WBLSFM',
        pressEntry: 'WBLS 107.5 FM is part of the MediaCo New York radio cluster, alongside sister-station WQHT 97.1 FM.',
        pressOficialBoilerPlate: `WBLS 107.5 FM is a New York Urban Adult Contemporary station with 2.3 million listeners. 60% of WBLS' listeners are between the ages of 25-54. Broadcasting from its signal are award winning and internationally known personalities like Steve Harvey, Deja, Jus Nik, Lenny Green, Donnie McClurkin, Dahved Levy, DJ Marley Marl and more. WBLS has been America's most recognizable black radio station, as the #1 source for R&B music and lifestyle reaching the tri-state area. For more information, please explore this site, and follow us on social:`,
        subscribeText: `Get all the latest news and updates from your friends at WBLS! By subscribing to our WBLS email alerts you will be informed on the latest news, marketing, and promotional information for the world's best sound, WBLS.`,
        subscribeHubspotId: 'b7cdfdde-1189-4da9-9cc7-2135952f3029',
        contestRulesCompanyName: 'd/b/a WBLS 107.5 (“WBLS 107.5” or “MediaCo”)',
        talentPath: '/talent/',
        postsPath: '/wbls-news/',
        postsName: 'WBLS News',
        topStories: 'Top Stories',
        moreFrom: 'MORE FROM WBLS',
        defaultAuthor: 'WBLS Staff',
        adsTag: 'https://pubads.g.doubleclick.net/gampad/ads?iu=/4052,22551419753/wbls/videopreroll&description_url=https%3A%2F%2Fwww.wbls.com%2F&tfcd=0&npa=0&sz=602x400&gdfp_req=1&output=vast&unviewed_position_start=1&env=vp&impl=s&correlator='
    }
}

export function isDarkTheme() {
    return isHot97() ? ' is-dark-theme' : ''
}

export function isHot97() {
    return process.env.NEXT_PUBLIC_TARGET === 'hot97'
}

export function getDictionary(key) {
    return dictionary[process.env.NEXT_PUBLIC_TARGET][key]
}

export function getImagePath(key) {
    return `/images/${process.env.NEXT_PUBLIC_TARGET}/${key}`
}
