interface PlayButtonSVGProps {
    fill?: string
}

export default function PlayButtonSVG({fill = '#000'}: PlayButtonSVGProps) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="63" height="63" viewBox="0 0 63 63" fill={fill}>
            <g>
                <path d="M57.0938 31.5C57.0938 24.7121 54.3973 18.2022 49.5975 13.4025C44.7978 8.60273 38.2879 5.90625 31.5 5.90625C24.7121 5.90625 18.2022 8.60273 13.4025 13.4025C8.60273 18.2022 5.90625 24.7121 5.90625 31.5C5.90625 38.2879 8.60273 44.7978 13.4025 49.5975C18.2022 54.3973 24.7121 57.0938 31.5 57.0938C38.2879 57.0938 44.7978 54.3973 49.5975 49.5975C54.3973 44.7978 57.0938 38.2879 57.0938 31.5ZM0 31.5C0 23.1457 3.31874 15.1335 9.22614 9.22614C15.1335 3.31874 23.1457 0 31.5 0C39.8543 0 47.8665 3.31874 53.7739 9.22614C59.6813 15.1335 63 23.1457 63 31.5C63 39.8543 59.6813 47.8665 53.7739 53.7739C47.8665 59.6813 39.8543 63 31.5 63C23.1457 63 15.1335 59.6813 9.22614 53.7739C3.31874 47.8665 0 39.8543 0 31.5ZM23.1697 18.1002C24.1049 17.5834 25.2369 17.5957 26.1598 18.1617L43.8785 28.9898C44.7521 29.5312 45.2936 30.4787 45.2936 31.5123C45.2936 32.5459 44.7521 33.4934 43.8785 34.0348L26.1598 44.8629C25.2492 45.4166 24.1049 45.4412 23.1697 44.9244C22.2346 44.4076 21.6562 43.4232 21.6562 42.3527V20.6719C21.6562 19.6014 22.2346 18.617 23.1697 18.1002Z" fill={fill} />
            </g>
            <defs>
                <clipPath id="clip0_183_869">
                    <rect width="63" height="63" fill="#FFF"/>
                </clipPath>
            </defs>
        </svg>
    )
}