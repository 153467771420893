import { getDictionary, getImagePath } from "@/src/dictionary"
import Image from 'next/image'
import { memo, useEffect } from "react"
import { SocialMedia } from "../socials"
import Link from "next/link"

type menuItem = {
    name: string,
    path: string
}

type FooterProps = {
    mainMenuItems: menuItem[],
    socialMenuItems:  menuItem[],
    footerMenuItems: menuItem[]
}

const Footer = ({ mainMenuItems, socialMenuItems, footerMenuItems }: FooterProps) => {

    return (
        <div className="hidden md:flex p-24 pb-4 justify-between bg-black flex-wrap flex-col mb-[-16px]">
            <div className="flex justify-between">
                <span className="text-white text-sm font-[Judge]">
                    Copyright {new Date().getFullYear()} {getDictionary('websiteName')}
                </span>
                <div className="flex flex-row space-x-4 justify-items-end">
                    {
                        mainMenuItems.map((item: any) => {
                            return (
                                <span key={item.path}
                                    className='text-white text-lg leading-6 border-spacing-1 border-l-2 border-l-hot-yellow space-x-4 h-5'>
                                    <Link prefetch={false} className="text-white uppercase footer-menu-item pr-8 pl-1 font-[Judge]" href={item.path}>{item.name}</Link>
                                </span>
                            )
                        })
                    }
                </div>
            </div>            
            <div className="flex">
                <div className="py-10">
                    <Link prefetch={false} 
                        aria-label='Logo link to homepage'
                        href='/'>
                    <Image 
                        src={getImagePath('website-logo.png')}
                        title={getDictionary(`indexTitle`)}
                        blurDataURL={getImagePath('website-logo.png')}
                        alt={getDictionary('indexTitle')}
                        placeholder="blur"
                        quality={50}
                        width={89}
                        height={97}
                    />
                </Link>
                </div>
            </div>
            <div className="flex justify-between items-center">
                {
                    footerMenuItems.map((item: any) => {
                        return (
                            <span key={item.path} className="text-white">
                                <Link prefetch={false} className="text-white text-sm capitalize font-[Judge]" href={item.path}>{item.name}</Link>
                            </span>
                        )
                    })
                }
                
                <SocialMedia socialItems={socialMenuItems} footer buttonStyles="text-white px-2" />
            </div>     
        </div>
    )
}

export default memo(Footer)