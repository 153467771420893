interface StopButtonSVGProps {
    fill?: string
}

export default function StopButtonSVG({fill = '#000'}: StopButtonSVGProps) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg"  height="63" width="63" shapeRendering="geometricPrecision" textRendering="geometricPrecision" imageRendering="optimizeQuality" fillRule="evenodd" clipRule="evenodd" viewBox="0 0 512 512">
            <path fill={fill} fillRule="nonzero" d="M256 0c70.69 0 134.69 28.66 181.02 74.98C483.34 121.3 512 185.31 512 256c0 70.69-28.66 134.69-74.98 181.02C390.69 483.34 326.69 512 256 512c-70.69 0-134.7-28.66-181.02-74.98C28.66 390.69 0 326.69 0 256c0-70.69 28.66-134.7 74.98-181.02C121.3 28.66 185.31 0 256 0zm23.13 164.44h56.62v183.12h-56.62V164.44zm-102.88 0h56.62v183.12h-56.62V164.44zm229.68-58.37C367.57 67.7 314.55 43.96 256 43.96c-58.55 0-111.57 23.74-149.93 62.11C67.7 144.43 43.96 197.45 43.96 256c0 58.55 23.74 111.57 62.11 149.93 38.36 38.37 91.38 62.11 149.93 62.11 58.55 0 111.57-23.74 149.93-62.11 38.37-38.36 62.11-91.38 62.11-149.93 0-58.55-23.74-111.57-62.11-149.93z"/>
        </svg>
    )
   
}