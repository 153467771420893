const { useEffect, useState } = require('react')

export default function Comscore() {

    const [windowLoaded, setWindowLoaded] = useState(false)

    useEffect(() => setWindowLoaded(true), [])

    if (!windowLoaded) {
        return <></>
    }

    fetch(`/comscore`, { cache: 'no-store' }).catch(() => {})
    return <> {window.COMSCORE && window.COMSCORE.beacon({c1: "2", c2: "6035391"})} </>
  }